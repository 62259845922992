





























import { trimSpaceToUnderscore } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseAccountingTax, ResponseListAccountingTax } from "@interface/accounting-tax.interface";
import { IOption } from "@interface/common.interface";
import { accountingTaxService } from "@service/accounting-tax.service";
import Vue from "vue";
export default Vue.extend({
  name: "CSelectTaxCode",
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    taxType: {
      type: String,
      default: undefined,
    }
  },
  data() {
    this.searchTax = debounceProcess(this.searchTax, 300);
    return {
      optTax: [] as IOption<ResponseAccountingTax>[],
      loading: false,
    };
  },
  created() {
    this.searchTax();
  },
  methods: {
    getListTax(params: RequestQueryParamsModel): Promise<ResponseListAccountingTax> {
      return accountingTaxService.listOfTax(params);
    },
    onSelect(e: string, meta: ResponseAccountingTax): void {
      this.$emit("input", e);
      this.$emit("on-select", { value: e, meta });
    },
    handleChange(): void {
      this.searchTax();
    },
    async searchTax(search = ""): Promise<void> {
      try {
        this.loading = true;
        const params: RequestQueryParamsModel = {
          limit: 10,
          page: 0,
          search: `taxType~${trimSpaceToUnderscore(this.taxType)}`
        };
        if (search) params.search = `taxType~${trimSpaceToUnderscore(this.taxType)}_AND_description~*${search}*_OR_code~${search}`;
        const res = await this.getListTax(params);
        this.optTax = res.data.map(x => ({ key: x.code, value: x.id, meta: x }));
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
  },
});
